import React from "react";

function Donation() {
  return (
    <section className="donation-area donation-bg fix pt-65">
      <div className="container custom-container">
        <div className="donation-wrap">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-7">
              <div className="donation-content">
                <div className="third-title-style">
                  <h2>
                    themebey<span>ond</span>
                  </h2>
                  <div className="inner">
                    <h2>WORLDS MEET REAL</h2>
                    <h6 className="vertical-title">donation</h6>
                    <p>
                      Beberapa orang mengira mereka berada dalam komunitas,
                      tetapi mereka hanya dekat. Komunitas sejati membutuhkan
                      komitmen dan keterbukaan. Ini adalah kesediaan untuk
                      memperluas dirimu untuk bertemu dan mengenal yang lain.
                    </p>
                  </div>
                  <a href="/#" className="btn rotated-btn">
                    JOIN NOW
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5">
              <div className="donation-img text-center">
                <img src="assets/img/images/dontaion_img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Donation;
