import React from "react";

function MatchArea() {
  return (
    <section className="my-match-area my-match-bg pb-120">
      <div className="container custom-container">
        <div className="row">
          <div className="col-12">
            <div className="third-section-title text-center mb-75">
              <h2>
                see <span>my</span> MATCHES
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="my-match-wrap">
              <div
                className="my-match-box-wrap wow fadeInDown"
                data-wow-delay=".2s"
              >
                <img
                  src="assets/img/bg/my_match_box.png"
                  alt=""
                  className="match-box-bg"
                />
                <ul>
                  <li>
                    <div className="my-match-team">
                      <div className="team-one">
                        <a href="/#">
                          <img
                            src="assets/img/team/my_match_clan01.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="vs">
                        <img src="assets/img/team/match_vs02.png" alt="" />
                      </div>
                      <div className="team-one">
                        <a href="/#">
                          <img
                            src="assets/img/team/my_match_clan02.png"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="my-match-info">
                      <a
                        href="https://www.twitch.tv/shroud"
                        target="_blank"
                        rel="noreferrer"
                        className="live-btn"
                      >
                        Live MATCHES
                      </a>
                      <h5>skyward sword</h5>
                      <span>10th Mar 2024.</span>
                    </div>
                  </li>
                  <li>
                    <a
                      href="https://www.twitch.tv/shroud"
                      target="_blank"
                      rel="noreferrer"
                      className="watch-stream"
                    >
                      <i className="fas fa-podcast" /> watch stream
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="my-match-box-wrap wow fadeInDown"
                data-wow-delay=".4s"
              >
                <img
                  src="assets/img/bg/my_match_box.png"
                  alt=""
                  className="match-box-bg"
                />
                <ul>
                  <li>
                    <div className="my-match-team">
                      <div className="team-one">
                        <a href="/#">
                          <img
                            src="assets/img/team/my_match_clan03.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="vs">
                        <img src="assets/img/team/match_vs02.png" alt="" />
                      </div>
                      <div className="team-one">
                        <a href="/#">
                          <img
                            src="assets/img/team/my_match_clan04.png"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="my-match-info">
                      <a
                        href="https://www.twitch.tv/shroud"
                        target="_blank"
                        rel="noreferrer"
                        className="live-btn"
                      >
                        Live MATCHES
                      </a>
                      <h5>Call Of Duty Mascot</h5>
                      <span>10th Mar 2024.</span>
                    </div>
                  </li>
                  <li>
                    <a
                      href="https://www.twitch.tv/shroud"
                      target="_blank"
                      rel="noreferrer"
                      className="watch-stream"
                    >
                      <i className="fas fa-podcast" /> watch stream
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="my-match-box-wrap wow fadeInDown"
                data-wow-delay=".6s"
              >
                <img
                  src="assets/img/bg/my_match_box.png"
                  alt=""
                  className="match-box-bg"
                />
                <ul>
                  <li>
                    <div className="my-match-team">
                      <div className="team-one">
                        <a href="/#">
                          <img
                            src="assets/img/team/my_match_clan05.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="vs">
                        <img src="assets/img/team/match_vs02.png" alt="" />
                      </div>
                      <div className="team-one">
                        <a href="/#">
                          <img
                            src="assets/img/team/my_match_clan06.png"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="my-match-info">
                      <a
                        href="https://www.twitch.tv/shroud"
                        target="_blank"
                        rel="noreferrer"
                        className="live-btn"
                      >
                        Live MATCHES
                      </a>
                      <h5>Counter Strike Mascot</h5>
                      <span>10th Mar 2024.</span>
                    </div>
                  </li>
                  <li>
                    <a
                      href="https://www.twitch.tv/shroud"
                      target="_blank"
                      rel="noreferrer"
                      className="watch-stream"
                    >
                      <i className="fas fa-podcast" /> watch stream
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MatchArea;
