import React from "react";
function GamesAreatwo() {
  return (
    <section className="just-gamers-area just-gamers-bg pt-115 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-10">
            <div className="section-title title-style-three white-title mb-70">
              <h2>
                JUST FOR <span>GAMERS PC</span>
              </h2>
              <p>
                Compete with 100 player on a remote island for winner known
                issue where certain strategic
              </p>
            </div>
            <div className="just-gamers-list">
              <ul>
                <li>
                  <div className="just-gamers-list-icon">
                    <img src="assets/img/icon/gamer_list_icon01.png" alt="" />
                  </div>
                  <div className="just-gamers-list-content fix">
                    <h5>Good Atitude</h5>
                    <p>
                      Player wajib tidak berkata kasar dan tidak mengujar
                      kebencian ke siapapun
                    </p>
                  </div>
                </li>
                <li>
                  <div className="just-gamers-list-icon">
                    <img src="assets/img/icon/gamer_list_icon02.png" alt="" />
                  </div>
                  <div className="just-gamers-list-content fix">
                    <h5>No Program ilegal</h5>
                    <p>
                      Player tidak boleh memakai program ilegal, bila kedapatan
                      maka akan dikeluarkan dari clan
                    </p>
                  </div>
                </li>
                <li>
                  <div className="just-gamers-list-icon">
                    <img src="assets/img/icon/gamer_list_icon03.png" alt="" />
                  </div>
                  <div className="just-gamers-list-content fix">
                    <h5>Bukan mental pengemis</h5>
                    <p>
                      Player dilarang memanfaatkan sesama rekan clan dan
                      dilarang memanfaatkan rekan sendiri
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6 d-none d-lg-block">
            <div className="just-gamers-img">
              <img
                src="assets/img/images/just_gamers_img.png"
                alt=""
                className="just-gamers-character"
              />
              <div className="just-gamers-circle-shape">
                <img src="assets/img/images/gamers_circle_line.png" alt="" />
                <img
                  src="assets/img/images/gamers_circle_shape.png"
                  alt=""
                  className="rotateme"
                />
              </div>
              <img
                src="assets/img/images/just_gamers_chart.png"
                alt=""
                className="gamers-chart-shape"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GamesAreatwo;
