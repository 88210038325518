import React from "react";

function UpcomingGames() {
  return (
    <section className="upcoming-games-area upcoming-games-bg pt-120 pb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>SEPTEMBER 22, 2024</span>
                  <h4>
                    <a href="/#">zombie land</a>
                  </h4>
                </div>
                <div className="uc-game-price">
                  <h5>$19</h5>
                </div>
              </div>
              <p>
                Compete with players remote island winner takes showdown known
                issue.
              </p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/images/upcoming_game_thumb01.jpg" alt="" />
                <div className="upcoming-game-cart">
                  <a href="/#" className="btn transparent-btn">
                    <i className="fas fa-shopping-basket" />
                    JOIN NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>SEPTEMBER 22, 2024</span>
                  <h4>
                    <a href="/#">call of duty</a>
                  </h4>
                </div>
                <div className="uc-game-price">
                  <h5>$19</h5>
                </div>
              </div>
              <p>
                Compete with players remote island winner takes showdown known
                issue.
              </p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/images/upcoming_game_thumb02.jpg" alt="" />
                <div className="upcoming-game-cart">
                  <a href="/#" className="btn transparent-btn">
                    <i className="fas fa-shopping-basket" />
                    JOIN NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>SEPTEMBER 22, 2024</span>
                  <h4>
                    <a href="/#">sky hunter</a>
                  </h4>
                </div>
                <div className="uc-game-price">
                  <h5>$19</h5>
                </div>
              </div>
              <p>
                Compete with players remote island winner takes showdown known
                issue.
              </p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/images/upcoming_game_thumb03.jpg" alt="" />
                <div className="upcoming-game-cart">
                  <a href="/#" className="btn transparent-btn">
                    <i className="fas fa-shopping-basket" />
                    JOIN NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>SEPTEMBER 22, 2024</span>
                  <h4>
                    <a href="/#">NEW RELEASED!</a>
                  </h4>
                </div>
                <div className="uc-game-price">
                  <h5>$19</h5>
                </div>
              </div>
              <p>
                Compete with players remote island winner takes showdown known
                issue.
              </p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/images/upcoming_game_thumb04.jpg" alt="" />
                <div className="upcoming-game-cart">
                  <a href="/#" className="btn transparent-btn">
                    <i className="fas fa-shopping-basket" />
                    JOIN NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>SEPTEMBER 22, 2024</span>
                  <h4>
                    <a href="/#">NEW RELEASED!</a>
                  </h4>
                </div>
                <div className="uc-game-price">
                  <h5>$19</h5>
                </div>
              </div>
              <p>
                Compete with players remote island winner takes showdown known
                issue.
              </p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/images/upcoming_game_thumb05.jpg" alt="" />
                <div className="upcoming-game-cart">
                  <a href="/#" className="btn transparent-btn">
                    <i className="fas fa-shopping-basket" />
                    JOIN NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>SEPTEMBER 22, 2024</span>
                  <h4>
                    <a href="/#">NEW RELEASED!</a>
                  </h4>
                </div>
                <div className="uc-game-price">
                  <h5>$19</h5>
                </div>
              </div>
              <p>
                Compete with players remote island winner takes showdown known
                issue.
              </p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/images/upcoming_game_thumb06.jpg" alt="" />
                <div className="upcoming-game-cart">
                  <a href="/#" className="btn transparent-btn">
                    <i className="fas fa-shopping-basket" />
                    JOIN NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpcomingGames;
