import React from "react";

function BlogContent() {
  return (
    <section className="blog-area primary-bg pt-120 pb-175">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="blog-list-post blog-details-wrap">
              <div className="blog-list-post-content">
                <div className="blog-list-post-tag mb-25">
                  <a href="/#">shooting</a>
                </div>
                <h2>welcome PLAY FOR EVERYONE zombie</h2>
                <div className="blog-meta">
                  <ul>
                    <li>
                      bY <a href="/#">x-hunter</a> OCTOBER 19, 2024
                    </li>
                  </ul>
                </div>
                <p>
                  An online game dolor sit amet, consectetur adipiscing
                  eliSuspendiss mauris vitae lacus commodo iaculis eget vitae
                  magna. Nam leohe a volutpat quis iaculis viverra id quam.
                  Morbi pharetra, libero ut tempor finibus, metus massa pharetr
                  ornare pharetra felis risus eu quam.
                </p>
                <h4>gaming an addiction?</h4>
                <p>
                  Donec orci enim, bibendum a augue quis, aliquet cursus quam.
                  Pellentesque pulvin condimentum dictum, sapien nibh auctor
                  tortoris vulputate sapien tortor velit. Sed nulla congue euqua
                  molestie grvida ipsum. Curabitr ut lacus vitae tellus lacinia
                  pretium. Proin vestibulum sollcitudin tortor, quis auctor mi
                  rutrum non. Donec non eros eget purus lobortis imperdiet ac
                  vitae est.
                </p>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="blog-details-img">
                      <img
                        src="assets/img/blog/blog_details_img01.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="blog-details-img">
                      <img
                        src="assets/img/blog/blog_details_img02.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <p>
                  Aliquet cursus quam. Pellentesque pulvin condimentum dictum,
                  sapien auctor tortoris vulputate sapien tortor velit. Sed
                  nulla congue euqua molestie grvida ipsum. Curabitr ut lacus
                  vitae tellus lacinia pretium. Proin vestibulum sollcitudin
                  tortor, quis auctor mi rutrum non.
                </p>
                <blockquote>
                  “ LOREM IPSUM DOLOR AMET CONSECTETUR ADIPISCING ELIT, ukjSED
                  EIUSMOD TEMPOR INCIDIDUNT gaming LABORE ET DOLORE MAGNA
                  ALIQUA”
                </blockquote>
                <p>
                  Pellentesque pulvin condium dictum, sapien auctor tortoris
                  vulputate sapien tortor velit. Sed nulla congue euqua molestie
                  grvida ipsum. Curabitr lacus vitae tellus lacinia pretium.
                  Proin vestibulum sollcitudin tortor, quis auctor mi rutru.Nisl
                  nisi scelerisque eu ultrices vitae. Eu lobortis elementum nibh
                  tellus molestie. Tellus orci auctor augue mauris augue neque.
                  Sem fringilla ut morbi tincidunt augue interdum. Sed risus
                  ultricies tristique nulla aliquet enim.
                </p>
                <ul className="blog-details-list">
                  <li>Sapien auctor tortoris vulputate sapien ?</li>
                  <li>
                    Curabitr lacus vitae tellus lacinia pretium vulputate ?
                  </li>
                  <li>Tellus orci auctor augue mauris ?</li>
                </ul>
                <p>
                  Sapien auctor tortoris vulputate sapien tortor velit. Sed nul
                  congue euqua molestie grvida ipsums Curabitr lacus vitae
                  tellus lacinia pretium. Proin vestibulum sollcitudin tortor,
                  quis auctor rutru.Nisl nisi scelerisque eu ultrices vitae. Eu
                  lobortis elementum nibh tellus molestie.
                </p>
                <div className="blog-list-post-bottom">
                  <ul>
                    <li>
                      <i className="fas fa-tag" />
                      <span>tags :</span>
                      <a href="/#">dota2</a>
                      <a href="/#">esports</a>
                      <a href="/#">matches</a>
                    </li>
                    <li>
                      <span>SHARE :</span>
                      <div className="blog-post-share">
                        <a href="/#">
                          <i className="fab fa-facebook-f" />
                        </a>
                        <a href="/#">
                          <i className="fab fa-twitter" />
                        </a>
                        <a href="/#">
                          <i className="fab fa-pinterest-p" />
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="blog-comment mb-75">
              <div className="sidebar-widget-title blog-details-title mb-35">
                <h4>
                  COMMENTS <span>(3)</span>
                </h4>
              </div>
              <ul>
                <li>
                  <div className="comment-avatar-thumb">
                    <img src="assets/img/blog/comment_thumb01.jpg" alt="" />
                  </div>
                  <div className="comment-text">
                    <div className="comment-avatar-info">
                      <h4>
                        <a href="/#">alexander hartmann</a>
                        <span className="comment-time">
                          10 Mar 2024, 7:06 am
                        </span>
                      </h4>
                      <div className="comment-reply">
                        <a href="/#">
                          <i className="fas fa-reply" />
                        </a>
                      </div>
                    </div>
                    <p>
                      Sapien auctor tortoris vulputate sapien tortor Sed nul
                      congue euqua molestie grvida ipsums Curabitr lacus vitae
                      tellus lacinia pretium.
                    </p>
                  </div>
                </li>
                <li className="comment-children">
                  <div className="comment-avatar-thumb">
                    <img src="assets/img/blog/comment_thumb02.jpg" alt="" />
                  </div>
                  <div className="comment-text">
                    <div className="comment-avatar-info">
                      <h4>
                        <a href="/#">aretha wilson</a>
                        <span className="comment-time">
                          10 Mar 2024, 7:06 am
                        </span>
                      </h4>
                      <div className="comment-reply">
                        <a href="/#">
                          <i className="fas fa-reply" />
                        </a>
                      </div>
                    </div>
                    <p>
                      Sapien auctor tortoris vulputate sapien tortor Sed nul
                      congue grvida ipsums Curabitr lacus vitae tellus lacinia
                      pretium.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="comment-reply-box">
              <div className="sidebar-widget-title blog-details-title mb-35">
                <h4>
                  LEAVE A <span>COMMENT</span>
                </h4>
              </div>
              <form className="comment-form">
                <textarea
                  name="message"
                  id="comment-message"
                  placeholder="Your Comment"
                  defaultValue={""}
                />
                <div className="row">
                  <div className="col-md-6">
                    <input type="text" placeholder="Your Name*" />
                  </div>
                  <div className="col-md-6">
                    <input type="email" placeholder="Your Email*" />
                  </div>
                </div>
                <div className="comment-check-box mb-25">
                  <input type="checkbox" id="comment-check" />
                  <label htmlFor="comment-check">
                    Save my name and email in this browser for the next time I
                    comment.
                  </label>
                </div>
                <button className="btn btn-style-two">Submit</button>
              </form>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="blog-sidebar">
              <div className="widget mb-45">
                <div className="sidebar-about">
                  <div className="sidebar-about-thumb">
                    <img src="assets/img/blog/sidebar_about_thumb.jpg" alt="" />
                  </div>
                  <div className="sidebar-widget-title mb-15">
                    <h4>
                      ABOUT THE <span>AUTHOR</span>
                    </h4>
                  </div>
                  <div className="sidebar-about-content">
                    <p>
                      Donec orci enim, bibendum a augue quis, aliquet cursus
                      quamPellentesq.
                    </p>
                  </div>
                </div>
              </div>
              <div className="widget mb-45">
                <div className="sidebar-search">
                  <form>
                    <input type="text" placeholder="Type and hit enter..." />
                    <button>
                      <i className="fas fa-search" />
                    </button>
                  </form>
                </div>
              </div>
              <div className="widget mb-45">
                <div className="sidebar-widget-title mb-25">
                  <h4>
                    category <span>post</span>
                  </h4>
                </div>
                <div className="sidebar-cat">
                  <ul>
                    <li>
                      <a href="/#">ESPORTS ( 12 )</a>
                    </li>
                    <li>
                      <a href="/#">play store ( 03 )</a>
                    </li>
                    <li>
                      <a href="/#">pubg ( 09 )</a>
                    </li>
                    <li>
                      <a href="/#">zombieland ( 07 )</a>
                    </li>
                    <li>
                      <a href="/#">shooting ( 02 )</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="widget mb-45">
                <div className="sidebar-widget-title mb-25">
                  <h4>
                    Recent <span>post</span>
                  </h4>
                </div>
                <div className="rc-post-list">
                  <ul>
                    <li>
                      <div className="rc-post-thumb">
                        <a href="/#">
                          <img
                            src="assets/img/blog/rc_post_thumb01.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="rc-post-content">
                        <h5>
                          <a href="/#">EVERYONE zombie</a>
                        </h5>
                        <span>OCTOBER 19, 2024</span>
                      </div>
                    </li>
                    <li>
                      <div className="rc-post-thumb">
                        <a href="/#">
                          <img
                            src="assets/img/blog/rc_post_thumb02.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="rc-post-content">
                        <h5>
                          <a href="/#">Archery World Tour</a>
                        </h5>
                        <span>OCTOBER 19, 2024</span>
                      </div>
                    </li>
                    <li>
                      <div className="rc-post-thumb">
                        <a href="/#">
                          <img
                            src="assets/img/blog/rc_post_thumb03.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="rc-post-content">
                        <h5>
                          <a href="/#">The Ranger Magic</a>
                        </h5>
                        <span>OCTOBER 19, 2024</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="widget mb-45">
                <div className="sidebar-widget-title mb-25">
                  <h4>
                    TRENDING <span>MATCHES</span>
                  </h4>
                </div>
                <div className="sidebar-trending-match">
                  <ul>
                    <li>
                      <div className="sidebar-trend-match-thumb">
                        <a href="/#">
                          <img
                            src="assets/img/blog/trend_match_thumb01.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="sidebar-trend-match-action">
                        <span>0</span> <span>-</span> <span>3</span>
                      </div>
                      <div className="sidebar-trend-match-thumb">
                        <a href="/#">
                          <img
                            src="assets/img/blog/trend_match_thumb02.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="sidebar-trend-match-date">
                        <span>OCTOBER 19, 2024</span>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar-trend-match-thumb">
                        <a href="/#">
                          <img
                            src="assets/img/blog/trend_match_thumb03.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="sidebar-trend-match-action">
                        <span>0</span> <span>-</span> <span>7</span>
                      </div>
                      <div className="sidebar-trend-match-thumb">
                        <a href="/#">
                          <img
                            src="assets/img/blog/trend_match_thumb04.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="sidebar-trend-match-date">
                        <span>OCTOBER 19, 2024</span>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar-trend-match-thumb">
                        <a href="/#">
                          <img
                            src="assets/img/blog/trend_match_thumb05.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="sidebar-trend-match-action">
                        <span>0</span> <span>-</span> <span>3</span>
                      </div>
                      <div className="sidebar-trend-match-thumb">
                        <a href="/#">
                          <img
                            src="assets/img/blog/trend_match_thumb06.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="sidebar-trend-match-date">
                        <span>OCTOBER 19, 2024</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="widget">
                <div className="sidebar-widget-title mb-25">
                  <h4>
                    TAGS <span>post</span>
                  </h4>
                </div>
                <div className="sidebar-tag-list">
                  <ul>
                    <li>
                      <a href="/#">pubg</a>
                    </li>
                    <li>
                      <a href="/#">dota 2</a>
                    </li>
                    <li>
                      <a href="/#">zombie</a>
                    </li>
                    <li>
                      <a href="/#">call of duty</a>
                    </li>
                    <li>
                      <a href="/#">development</a>
                    </li>
                    <li>
                      <a href="/#">zombieland 2</a>
                    </li>
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogContent;
