import React from "react";

function TeamMem() {
  return (
    <section className="team-member-area pt-115 pb-125">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-three text-center mb-70">
              <h2>
                Our team <span>member</span>
              </h2>
              <p>
                Compete with 100 players on a remote island for winner takes
                showdown known issue where certain skin strategic
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="team-member-box text-center mb-50">
              <div className="team-member-thumb">
                <img src="assets/img/team/team_member01.jpg" alt="" />
                <div className="team-member-social">
                  <ul>
                    <li>
                      <a href="http://www.instagram.com/parak_kuman">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="http://www.youtube.com/@parakuman">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="http://www.tiktok.com/@parakuman">
                        <i className="fab fa-pinterest-p" />
                      </a>
                    </li>
                    <li>
                      <a href="mailto:banglalatst12@gmail.com">
                        <i className="fas fa-envelope" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-member-content">
                <h4>
                  <a href="/#">tomas aleman</a>
                </h4>
                <span>Marketing CEO</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-member-box text-center mb-50">
              <div className="team-member-thumb">
                <img src="assets/img/team/team_member02.jpg" alt="" />
                <div className="team-member-social">
                  <ul>
                    <li>
                      <a href="http://www.instagram.com/parak_kuman">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="http://www.youtube.com/@parakuman">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="http://www.tiktok.com/@parakuman">
                        <i className="fab fa-pinterest-p" />
                      </a>
                    </li>
                    <li>
                      <a href="mailto:banglalatst12@gmail.com">
                        <i className="fas fa-envelope" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-member-content">
                <h4>
                  <a href="/#">james wiseman</a>
                </h4>
                <span>Marketing CEO</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-member-box text-center mb-50">
              <div className="team-member-thumb">
                <img src="assets/img/team/team_member03.jpg" alt="" />
                <div className="team-member-social">
                  <ul>
                    <li>
                      <a href="http://www.instagram.com/parak_kuman">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="http://www.youtube.com/@parakuman">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="http://www.tiktok.com/@parakuman">
                        <i className="fab fa-pinterest-p" />
                      </a>
                    </li>
                    <li>
                      <a href="mailto:banglalatst12@gmail.com">
                        <i className="fas fa-envelope" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-member-content">
                <h4>
                  <a href="/#">emily watson</a>
                </h4>
                <span>Head of Iaea</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-member-box text-center mb-50">
              <div className="team-member-thumb">
                <img src="assets/img/team/team_member04.jpg" alt="" />
                <div className="team-member-social">
                  <ul>
                    <li>
                      <a href="http://www.instagram.com/parak_kuman">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="http://www.youtube.com/@parakuman">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="http://www.tiktok.com/@parakuman">
                        <i className="fab fa-pinterest-p" />
                      </a>
                    </li>
                    <li>
                      <a href="mailto:banglalatst12@gmail.com">
                        <i className="fas fa-envelope" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-member-content">
                <h4>
                  <a href="/#">alexandra paol</a>
                </h4>
                <span>Web Developer</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TeamMem;
